import { Component } from '@angular/core';
import { CommonService } from './Service/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'patient-engagement-app';
constructor(private commonService:CommonService){

}
  ngOnInit(){
    this.commonService.getData().subscribe((value:string)=>{
      console.log(value);
    });
  }
}
